@import '../variables.scss';
.contact{
    width:100%;
    height:auto;
    padding-top:150px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__divContact{
        width:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .divEmail{
        padding-left: 120px;
        width: 90%;
    }
    &__imgEmail{
        margin:0;
        margin-top: 20px;
        width:7%;
    }
    &__imgContact{
        margin-left: 100px;
        width:20%;
    }
    
    &__imgNumber{
        margin-left:-15px;

        margin-top: 20px;
        width:25%;
    }
    &__imgNetwork{
        margin-left:-12px;
        margin-top: 20px;
        width:17%;
    }
    .webTech {
        font-size: 20px;
        color: white;
        font-family: 'Akeila';
        padding-bottom: 20px;
        padding-left:8px;
        letter-spacing: 0.5px;
        margin: 0;
    }


}