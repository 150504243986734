@import '../variables.scss';
.portfolio{
    width:100%;
    height:auto;
    padding-top:150px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__divPortfolio{
        width:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    &__imgPortfolio{
        margin-left: 100px;
        width:20%;
    }
    &__imgSubPortfolio{
        margin-left: 120px;
        margin-top: 20px;
        width:10%;
    }
    &__imgSitePortfolio{
        margin-left: 120px;
        margin-top: 20px;
        width:17%;
    }
    .gridPortfolio{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: center; 
        margin: 0 auto;
        padding: 2% 8%;
        gap:20px;
    }
    .gridItemDessin {
        height: 72vh;
        .gridImg {
            width: 100%;
            height: 100%;
           
    
            img {
                width: 100%;
                height: auto;
            
            }
        }
        
    }
    .gridItem{
        height: 50vh;
        .gridImg {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative; 
            z-index: 1;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center 45%;
            }
        }
        
    }
    .gridItem, .gridItemDessin{
        background-color: $secondary-color;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        .gridInfos {
            padding: 2% 10%;
            width: 100%;
            min-height: 50%;
            height:auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            opacity: 0; 
            visibility: hidden;
            transition: opacity 0.5s, visibility 0.5s;
            z-index: 2;
    
            .webTitle {
                font-size: 25px;
                color: white;
                font-family: 'Akeila';
                font-weight: bold;
                padding: 0;
                letter-spacing: 2px;
                border-bottom: 1px solid white;
                margin: 15px 0;
                
            }
           
            .webDesc, .webTech {
                font-size: 20px;
                color: white;
                font-family: 'Akeila';
                padding-bottom: 20px;
                letter-spacing: 0.5px;
                margin: 0;
            }
            .webTech{
                color:$primary-color;
                padding-bottom: 10px;
                span{
                    color: white;
                    font-size: 15px;
                }
            }
    
        }
    
        &:hover .gridInfos {
            opacity: 1; 
            visibility: visible;
        }
    }
    

    
    
}