@font-face {
  font-family: 'AkiraExpanded'; 
  src: url('../public/assets/fonts/akira-expanded.otf') format('truetype'); 
}
@font-face {
  font-family: 'InsightMelody'; 
  src: url('../public/assets/fonts/insight-melody.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Ganglion'; 
  src: url('../public/assets/fonts/Ganglion.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Yachting'; 
  src: url('../public/assets/fonts/yachting.ttf') format('truetype'); 
}

@font-face {
  font-family: 'Sunshine'; 
  src: url('../public/assets/fonts/Sunshine.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Beezle'; 
  src: url('../public/assets/fonts/Beezle.otf') format('truetype'); 
}
@font-face {
  font-family: 'Akeila'; 
  src: url('../public/assets/fonts/Akeila.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Ramongs'; 
  src: url('../public/assets/fonts/Ramongs.ttf') format('truetype'); 
}

@import 'variables';
body {
  background-color: $secondary-color;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


