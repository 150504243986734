@import '../variables.scss';
.home{
    background: url('../../public/assets/background.jpg');
    background-size: cover;
    background-attachment: fixed;
    width:100%;
    height:580vh;
    padding-top:60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__divImg{
        width:50%;
        margin-top:100px;
        margin-bottom: 200px;

    }
    &__img{
        width:100%;
      
    }
    &__firstDesign, &__secondDesign{
        font-size: 40px;
        font-family: 'Ganglion';
        font-weight: bold;
        color:white;
        margin:0
        
    }
    &__firstPortDesign, &__secondPortDesign{
        font-size: 20px;
        font-family: 'Ganglion';
        font-weight: bold;
        color:white;
        margin:0
    }
    &__firstPortDesign{
        text-align: start;
    }
    &__secondPortDesign{
        text-align: end;
    }
    &__divTextPortfolio{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:90%;
        margin-left: 100px;
        
    }
    &__secondDesign, &__secondDesignPres{
        text-align: end;
        
    }
    &__divPresentation{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 150px;
        width:100%;   
        margin-bottom: 100px;
    }
    &__divPresPhoto{
        transform: rotate(-5deg);

        width:40%;
        padding: 40px;
    }
    &__firstDesignPres, &__secondDesignPres{
        font-size: 30px;
        font-family: 'Ganglion';
        font-weight: bold;
        color:white;
        margin:0
        
    }

    &__infoPhoto{
        transform: rotate(3deg);
        background-color: $secondary-color;
        width:60%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 20px 40px;
        padding: 0 40px;
        border: 3px solid white;

        .titlePhoto{
            font-size: 40px;
            font-family: 'Akeila';
            text-transform: uppercase;
            color:white;

            span{
                font-family: 'Beezle';
            }
        }
        div{
            border-bottom: 1px solid white;
        }
        .descPhoto{
            font-size: 16px;
            font-family: 'Akeila';
            color:white;
        }
    }
    &__imgZoom{
        width:100%;
        cursor: zoom-in;

    }
    &__divCV{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
        width:100%;
        margin-bottom: 100px;
   
    }
 
    &__divCVPhoto{
        transform: rotate(5deg);

        width:40%;
        padding: 100px;
    }
    &__zoomCV{
        transform: rotate(-3deg);
        background-color: $secondary-color;
        width:50%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 40px;
        padding: 0 40px;
        border: 3px solid white;
        .cursorText{
            color:white;
            font-family: 'Akeila';
            font-size: 20px;
        }
    }

    &__divPortfolio{
        width:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    &__imgPortfolio{
        
        width:20%;
    }
    &__imgSubPortfolio{
        margin-left: 120px;
        margin-top: 20px;
        width:10%;
    }
    &__imgSitePortfolio{
        margin-left: 120px;
        margin-top: 20px;
        width:17%;
    }
    .gridPortfolio{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: center; 
        margin: 0 auto;
        padding: 2% 8%;
        gap:20px;
    }
    .gridItem {
        background-color: $secondary-color;
        border-radius: 15px;
        min-height: 50vh;
        overflow: hidden;
        height: 20vh;
        position: relative;
    
        .gridImg {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative; 
            z-index: 1;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center 45%;
            }
        }
    
        .gridInfos {
            padding: 2% 10%;
            width: 100%;
            min-height: 50%;
            height:auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            opacity: 0; 
            visibility: hidden;
            transition: opacity 0.5s, visibility 0.5s;
            z-index: 2;
    
            .webTitle {
                font-size: 25px;
                color: white;
                font-family: 'Akeila';
                font-weight: bold;
                padding: 0;
                letter-spacing: 2px;
                border-bottom: 1px solid white;
                margin: 15px 0;
                
            }
           
            .webDesc, .webTech {
                font-size: 20px;
                color: white;
                font-family: 'Akeila';
                padding-bottom: 20px;
                letter-spacing: 0.5px;
                margin: 0;
            }
            .webTech{
                color:$primary-color;
                padding-bottom: 10px;
                span{
                    color: white;
                    font-size: 15px;
                }
            }
    
        }
    
        &:hover .gridInfos {
            opacity: 1; 
            visibility: visible;
        }
    }
    .divBtnPortfolio{

        .btnPortfolio {
            background-color: $primary-color; /* Fond blanc */
            color: $secondary-color; /* Texte noir */
            font-weight: bold;
            width: 200px;
            margin-top:40px;
            font-family: 'Akeila', sans-serif; /* Utilisation de la police 'Akeila' */
            font-size: 20px; /* Taille de la police */
            padding: 15px 20px; /* Espacement intérieur pour agrandir le bouton */
            border: none; /* Pas de bordure */
            cursor: pointer; /* Change le curseur en main quand on survole le bouton */
            transition: transform 0.3s ease; /* Animation légère quand on clique */
            box-shadow: -6px 6px 0px 0px rgba($secondary-color, 0.75); /* Ombre portée rétro en bas à gauche */
            border-radius: 5px; /* Bords légèrement arrondis pour un look moderne */
        }
        
        .btnPortfolio:hover {
            transform: translateY(-2px); /* Effet de soulèvement au survol */
            box-shadow: -6px 6px 0px 0px rgba(white, 0.75); /* Ombre portée rétro en bas à gauche */

            background-color: $secondary-color; /* Fond blanc */
            color: white; /* Texte noir */

        }
        
    }
    

    
    
}